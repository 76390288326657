/* eslint-disable max-params */
/* eslint-disable @angular-eslint/prefer-standalone-component */
/* eslint-disable @angular-eslint/prefer-standalone */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { EMPTY, Subscription, delay, map, of } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { AppService } from './app.service';
import { AuthenticatedUserService } from './authenticated-user.service';
import { BrandingService } from './branding.service';
import { DOCUMENT } from '@angular/common';
import { EnterDetailsComponent } from './enter-details/enter-details.component';
import { FactFindInfo } from './FactFindInfo';
import { IdleUserService } from './idle-user.service';
import { MwIdleTimerComponent } from './shared/mw-dialog/mw-idletimer/mw-idletimer.component';
import { PDFExportComponent } from "@progress/kendo-angular-pdf-export";
import { PrivateComponent } from "./private/private.component";

const hexToRgb = (hex: string): string => {
  // Remove the hash character, if present
  const h = hex.replace(/^#/u, '');

  // Parse the hex values into RGB values
  const bigint = parseInt(h, 16);
  // eslint-disable-next-line no-magic-numbers
  const r = (bigint >> 16) & 255;
  // eslint-disable-next-line no-magic-numbers
  const g = (bigint >> 8) & 255;
  // eslint-disable-next-line no-magic-numbers
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [AppService]
})
export class AppComponent implements OnInit, OnDestroy {
  @Output()
  private readonly faviconChanged = new EventEmitter<string>();

  @Output()
  private readonly loaded = new EventEmitter<FactFindInfo>();

  private factFindInfo = new FactFindInfo();

  isEnterDetail = false;

  logoPath: string;

  showHeader = true;
  isLogin: boolean;

  enterDetailsRef: EnterDetailsComponent | null;

  @ViewChild('pdf') pdf!: PDFExportComponent;

  @ViewChild("idleCountdown") idleCountdownDialog: MwIdleTimerComponent;

  constructor(
    public authenticatedUserService: AuthenticatedUserService,
    public brandingService: BrandingService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private idleUserService: IdleUserService
  ) {
    if (
      window.location.pathname === '/authenticate' ||
      window.location.pathname === '/login' ||
      window.location.pathname === '/thank-you'
    ) {
      this.showHeader = false;
    }

    if (window.location.pathname === '/login') {
      this.isLogin = true;
    }

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (val.url.split('?')[0] === '/authenticate' || val.url.split('?')[0] === '/thank-you') {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }

        if (val.url.split('?')[0] === '/login') {
          this.isLogin = true;
          this.showHeader = false;
        } else {
          this.isLogin = false;
        }
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.updateLogoPath();
    this.loaded.emit(this.factFindInfo);

    this.brandingService.branding$.subscribe((branding) => {
      if (branding === null) return;

      this.faviconChanged.emit(this.brandingService.favIconUrl);

      this.factFindInfo.changed = new Date();
      this.factFindInfo.branding = branding.name;

      const rootStyleElement = document.getElementById('rootStyle');
      if (rootStyleElement) {
        const colors = this.brandingService.colors;
        rootStyleElement.innerHTML = `
        :root {
            --primary-color:${colors.primary};
            --primary-color-rgb:${hexToRgb(colors.primary)};
            --secondary-color:${colors.secondary};
            --backdrop-color:${colors.backdrop};
            --detail-color:${colors.detail};
            --hint-color:${colors.hint};
            --light-color: ${colors.light};
            --dark-color:${colors.dark};
            --frame-color:${colors.frame};
            --support-color:${colors.support};
            --danger-color:${colors.danger};
            --success-color:${colors.success};
            --brand-indigo:${colors.indigo};
            --brand-silver:${colors.silver};
            --field-border-radius:${this.brandingService.borderRadius};
        }
        `;
      }

      this.changeDetectorRef.detectChanges();
    });

    this.subscribeIdleUserServiceEvents();

    void this.idleUserService.setRecurredRemainingSessionDurationFollower();
  }

  ngOnDestroy() {
    this.idleUserService.stopWatching();
  }

  windowWidth: number;

  @HostListener('window:resize', ['$event'])
  windowResize() {
    this.updateLogoPath();
  }

  private updateLogoPath() {
    const mobileWindowWidth = 800;
    this.logoPath = window.innerWidth < mobileWindowWidth ? 'mobile-logo' : 'logo';
  }

  enterDetailsRefSubscription?: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onActivate(elementRef: any) {
    if (!(elementRef instanceof PrivateComponent)) return;

    this.enterDetailsRefSubscription = elementRef.enterDetailsRefInitialized.subscribe((ref) => {
      this.enterDetailsRef = ref;
    });

    this.changeDetectorRef.detectChanges();
  }

  onDeactivate() {
    this.enterDetailsRefSubscription?.unsubscribe();
    this.enterDetailsRef = null;
  }

  generatePDF() {
    const showAllPagesDelay = 500;
    const saveAsDelay = 1000;
    const defaultFontSize = this.document.getElementsByTagName('html')[0].style.fontSize;

    of(null)
      .pipe(
        map(() => {
          this.enterDetailsRef?.showAllPages();
          this.document.getElementsByTagName('html')[0].style.fontSize = '10px';
          return EMPTY;
        }),
        delay(showAllPagesDelay),
        map(() => {
          const today = new Date();
          const y = today.getFullYear();
          const padMaxLength = 2;
          const getMonthAdjustment = 1;
          const m = (today.getMonth() + getMonthAdjustment).toString().padStart(padMaxLength, '0');
          const d = today.getDate().toString().padStart(padMaxLength, '0');
          const hour = today.getHours().toString().padStart(padMaxLength, '0');
          const minute = today.getMinutes().toString().padStart(padMaxLength, '0');
          this.pdf.saveAs(`factfind_${y}${m}${d}_${hour}${minute}.pdf`);

          return EMPTY;
        }),
        delay(saveAsDelay),
        map(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.enterDetailsRef?.goToLastPage();
          this.document.getElementsByTagName('html')[0].style.fontSize = defaultFontSize;
          return EMPTY;
        })
      )
      .subscribe();
  }

  subscribeIdleUserServiceEvents() {
    // Event: The user's session is about to time out -> Change the countdown in TimeOut Dialog
    this.idleUserService.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleCountdownDialog.show(countdown);
    });

    // Event: The user has returned from being idle -> Close the TimeOut Dialog
    this.idleUserService.idle.onIdleEnd.subscribe(() => {
      this.idleCountdownDialog.close();
      
      void this.idleUserService.sendDummyRequestForSessionSlidingAndStopWatching().then(() => {
        void this.idleUserService.setRecurredRemainingSessionDurationFollower();
      });
    });

    // Event: The user's session has timed out -> Clear cookie and redirect to Login, show a message on the page
    this.idleUserService.idle.onTimeout.subscribe(() => {
      this.idleCountdownDialog.close();
      void this.authenticatedUserService.logOut();
      void this.router.navigate(['/login'], { queryParams: { timedOut: true } });
    });
  }
}

