import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { AdviserDashboardComponent } from './adviser-dashboard/adviser-dashboard.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticatedUserService } from './authenticated-user.service';
import { BooleanModule } from './enter-details/boolean/boolean.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CreateFactFindComponent } from './create-fact-find/create-fact-find.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { CustomErrorHandler } from './custom-error-handler';
import { CustomHttpInterceptor } from './CustomHttpInterceptor';
import { FactFindService } from './enter-details/factFind.service';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { GrantAccessComponent } from './grant-access/grant-access.component';
import { LoginComponent } from './login/login.component';
import { MainMenuComponent } from './mainmenu/mainmenu.component';
import { ManageOwnerComponent } from './manage-owner/manage-owner.component';
import { MwDialogModule } from './shared/mw-dialog/mw-dialog.module';
import { MwIconModule } from '../app/shared/mw-icon/mw-icon.module'
import { MwIdleTimerComponent } from './shared/mw-dialog/mw-idletimer/mw-idletimer.component';
import { MwSuccessModule } from './shared/mw-success/mw-success.module';
import { MwTableModule } from './shared/mw-table/mw-table.module';
import { MwToastModule } from './shared/mw-toast/mw-toast.module';
import { NgIdleModule } from '@ng-idle/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OwnerFormComponent } from './manage-owner/owner-form/owner-form.component';
import { OwnerUpdateDialogComponent } from './manage-owner/owner-form/owner-update-dialog.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PasswordSecurityComponent } from './password-security/password-security.component';
import { RegisterComponent } from './register/register.component';
import { Router } from '@angular/router';
import { SetPasswordComponent } from './set-password/set-password.component';
import { TemplatesComponent } from './templates/templates.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
  declarations: [
    AdviserDashboardComponent,
    AppComponent,
    CreateFactFindComponent,
    CreateUserComponent,
    ForbiddenComponent,
    GrantAccessComponent,
    LoginComponent,
    MainMenuComponent,
    ManageOwnerComponent,
    MwIdleTimerComponent,
    OwnerFormComponent,
    OwnerUpdateDialogComponent,
    PasswordSecurityComponent,
    RegisterComponent,
    SetPasswordComponent,
    TemplatesComponent,
    ThankYouComponent
  ],
  imports: [
    AppRoutingModule,
    BooleanModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientXsrfModule.withOptions({ headerName: 'XSRF-REQUEST-TOKEN', cookieName: 'XSRF-REQUEST-TOKEN' }),
    HttpClientModule,
    MwDialogModule,
    MwIconModule,
    MwSuccessModule,
    MwTableModule,
    MwToastModule,
    NgxSpinnerModule.forRoot({ type: 'ball-pulse-sync' }),
    PDFExportModule,
    ReactiveFormsModule,
    NgIdleModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (authenticatedUserService: AuthenticatedUserService, router: Router) => async () => {
        await authenticatedUserService.refresh();

        router.initialNavigation();
      },
      deps: [AuthenticatedUserService, Router],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: (factFindService: FactFindService) => () => {
        factFindService.registerEventHandler();
      },
      deps: [FactFindService, HttpClient, AuthenticatedUserService, Router],
      multi: true
    },
    { provide: FactFindService, deps: [HttpClient, AuthenticatedUserService, Router] }
  ]
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const customElement = createCustomElement(AppComponent, { injector: this.injector });

    customElements.define('fact-find', customElement);
  }
}
